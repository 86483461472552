<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank-transfer-in"
          class="px-5 py-5"
      >

        <v-card-text>

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
            >
              {{ error }}
            </v-alert>
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-subtitle-2 incident-date">Date</th>
                  <th class="text-subtitle-2 dependent-name">Dependent</th>
                  <th class="text-subtitle-2 member-name">Member</th>
                  <th class="text-subtitle-2 relationship-type">Relationship</th>
                  <th class="text-subtitle-2 cause-of-death">Cause</th>
                  <th class="text-subtitle-2 claim-status">Status</th>
                  <th class="text-subtitle-2 claim-action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                    v-for="claim in claims"
                    :key="claim.id"
                >
                  <td>{{ claim.incidentDate | formatDate }}</td>
                  <td>
                    <router-link :to="{ name: 'view_claim', params: {memberId: claim.user.id, claimId: claim.id} }">
                      {{ claim.user.names }}
                    </router-link>
                  </td>
                  <td>
                    {{ claim.dependent.names }}
                  </td>
                  <td>{{ claim.dependent.relationType.name }}</td>

                  <td>{{ claim.cause }}</td>
                  <td>{{ claimStatusMap[claim.status] }}</td>
                  <td>
                    <router-link :to="{ name: 'edit_claim', params: {memberId: claim.user.id, claimId: claim.id} }">
                      Edit
                    </router-link>
                  </td>

                </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card-text>


      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'CoreClaimsView',

  created() {
    this.fetchClaims()
  },

  methods: {
    fetchClaims() {
      api.fetchClaims().then(response => {
        this.claims = response.data
      }).catch(err => {
        let errorMessage = api.parseResponseError(err)
        this.errors.push(errorMessage)
      })
    }
  },

  data() {
    return {
      errors: [],
      claims: [],
      claimStatusMap: constants.getClaimStatusMap()
    }
  }
}
</script>

<style lang="sass">
th.claim-status, th.claim-action
  width: 5%

th.dependent-location, th.incident-date
  width: 10%


</style>
